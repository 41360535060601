import React from "react";
import Ccp from "../../Components/CCP/Ccp";

const CcpPage = () => {
  return (
    <div>
      <div>
        <Ccp />
      </div>
    </div>
  );
};

export default CcpPage;
