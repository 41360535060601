import React from "react";
import classes from "./Ccp.module.css";

const Ccp = () => {
  window.scrollTo(0, 0);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.wrapperContainer}>
        <h1>ELRAE TECHNOLOGIES LTD</h1>
        <h1>CONSUMER CODE OF PRACTICE</h1>
        <p>ELRAE TECHNOLOGIES LIMITED Code of Practice Document.</p>
        <h2>1. INTRODUCTION</h2>
        <p>
          ELRAE TECHNOLOGIES LTD (Nigeria), is a Value-Added Service provider in
          Nigeria. ELRAE TECHNOLOGIES LTD is regulated by the Nigerian
          Communications Commission (NCC).
        </p>
        <p>
          At ELRAE TECHNOLOGIES LTD, we consider honesty and integrity to be the
          hallmarks of long-term success which ultimately contributes to the
          ethical wellbeing of us all. Our professional standards are therefore
          exceptionally high, and we have systems and processes in place to
          ensure compliance with relevant laws. Amongst the most fundamental
          operating principles are adherence with all laws and regulations
          applicable to our business.
        </p>
        <p>
          This Code of Practice is issued pursuant to the Consumer Code of
          Practice Regulations 2024. The matters which the Code aims to address
          shall include but not limited to the under listed matters: •
          Reasonable meeting of Consumer requirements • Handling Consumer
          complaints and disputes • Consumer compensation in case of breach •
          Protection of information • Billing
        </p>
        <h2>2. DEFINITIONS AND INTERPRETATIONS</h2>
        <p>
          Unless otherwise defined, or the context otherwise requires,
          expressions defined in the General Consumer Code of Practice shall
          have the same meanings in this document including recitals and
          schedules
        </p>
        <p>
          "Quality of Service Regulation" means the Quality-of-Service
          Regulations 2024
        </p>
        <p>"Host" means the Licensee/ELRAE TECHNOLOGIES LTD</p>
        <p>"Consumer" means the customer</p>
        <h2>3. PROVISION OF INFORMATION TO CONSUMERS</h2>
        <p>
          3.1 ELRAE TECHNOLOGIES LTD shall upon request provide a copy of the
          contract or agreement for the provision of services and such contracts
          shall be in clear language.
        </p>
        <p>
          3.2 Copies of duly approved individual Code shall be available to
          consumers on request.
        </p>
        <h2>4. DESCRIPTION OF SERVICES</h2>
        <p>
          4.1 ELRAE TECHNOLOGIES LTD manages all aspects relating to provision
          of Value-Added Services as a Value-Added Service (VAS) Provider. The
          services include but are not limited to; gaming/lottery services which
          are provided to customers through short codes on major mobile
          networks.
        </p>
        <h2>5. PRICING INFORMATION</h2>
        <p>
          5.1 Contract shall not take effect until parties mutually agree on the
          pricing and composition thereof. Pricing components may include but
          not be limited to applicable rates or charges, calculation basis of
          each charge element, frequency, or basis of the collection of the
          charge, information as to whether charges are subject to change and
          the frequency of such changes and how information on such matters
          shall be communicated to the Consumer.
        </p>
        <h2>6. CONTRACT TERMS AND TERMINATION</h2>
        <p>
          6.1 Contracts with Consumers shall incorporate standard clauses
          relating to commencement date, minimum contract term (where
          applicable), manner and consequences of premature termination and
          calculation basis for payment of any penalty therefrom, situations
          where early termination may be permitted, renewal terms, installation,
          connection and decommission terms, and refund policies. Furthermore,
          all terms and conditions of a contract regarding the provision of any
          of our services shall be clearly stated in the contract or agreement
          in clear and plain language.
        </p>
        <h2>7. PRODUCT WARRANTIES AND MAINTENANCE</h2>
        <p>
          7.1 Where applicable, ELRAE TECHNOLOGIES LTD shall inform the consumer
          of any contractual warranty relating to any product or service
          offerings. Such information, where applicable, shall include how to
          obtain such warranty services.
        </p>
        <p>
          7.2 There shall be specific information to the consumers on the
          availability and provision of any maintenance service.
        </p>
        <h2>8. PROVISION OF SERVICES</h2>
        <p>
          8.1 Provision of services by ELRAE TECHNOLOGIES LTD shall be in
          accordance with the service supply time targets set out in the NCC
          Quality of Service regulations annexed to this Code. ELRAE
          TECHNOLOGIES LTD shall however not be liable for any delays or
          refusals of service requests, lack of site availability or
          infrastructure availability which is beyond its reasonable control.
        </p>
        <p>
          8.2 Further to clause 8.1 above, ELRAE TECHNOLOGIES LTD shall bear no
          responsibility for delays or refusals where such is attributable to
          the lack of credit worthiness of the Consumer.
        </p>
        <h2>9. FAULT REPAIR AND SERVICE INTERRUPTION</h2>
        <p>
          9.1 Relevant facilities and processes shall be implemented to ensure
          reporting of faults 24 hours a day by Consumer.
        </p>
        <p>
          9.2 The standard of fault repair as set out in the annexed Quality of
          Service regulations shall apply to the fault repair standards
          established by the Host.
        </p>
        <p>
          9.3 ELRAE TECHNOLOGIES LTD shall endeavour to give adequate
          notification of any planned downtime including details of the
          disruption or outage, the services and service area affected and any
          corresponding compensation or other remedies if applicable.
        </p>
        <h2>10.AVAILABILITY OF SERVICE</h2>
        <p>
          10.1 ELRAE TECHNOLOGIES LTD shall ensure that marketing presentation
          materials to potential Consumers indicate any known geographical or
          technical limitations which may substantially affect the performance
          of the Consumer services
        </p>
        <p>
          10.2 In addition to the above, marketing materials must indicate any
          limitations which restrict a particular group of persons, geographical
          area, particular period of time or limited availability of
          infrastructure or other materials
        </p>
        <h2>11. ADVERTISING OF PACKAGED SERVICES</h2>
        <p>
          11.1 The consumer shall be entitled to the supply of all components of
          a serviced package where the Host has marketed the provision of its
          service as part of a package.
        </p>
        <p>
          11.2 Appropriate information to potential Consumer shall be included
          in marketing materials by ELRAE TECHNOLOGIES LTD where it may be
          unlikely to supply any component of the service package
        </p>
        <p>
          11.3 The marketing materials may contain information on the pricing of
          the component of a service package; where this is so, the marketing
          materials shall also incorporate an estimate of the minimum total
          charge for the package and indicate any terms and conditions
          applicable to obtaining the component at the stated price.
        </p>
        <h2>12. DISCLAIMER AND TELEMARKETING</h2>
        <p>
          12.1 Services are subject to terms, conditions, and availability;
          ELRAE TECHNOLOGIES LTD shall include these disclaimers in
          advertisements.
        </p>
        <p>
          12.2 Consumers shall have the option to opt in or out of
          telemarketing, which shall comply with NCC regulations and provide
          accurate, non-misleading information.
        </p>
        <h2>13. BILLING INFORMATION</h2>
        <p>
          13.1 Where applicable: the following information shall be contained in
          invoices issued to the Consumer: Consumer name and billing address •
          ELRAE TECHNOLOGIES LTD current business name address argil registered
          number • Unique identification or invoice number • Date of invoice and
          billing period • Description of the services provided by ELRAE
          TECHNOLOGIES LTD for which consumer is charged • Historical summary of
          charges including total amount billed, applicable credits, advance
          payments or discounts, net amount payable by Consumer or repayable by
          Host as the case may be Payment/refund due date • Method of payment •
          Method of contact for complaints and billing inquiries.
        </p>
        <h2>14. ITEMIZATION OF CHARGES</h2>
        <p>
          14.1 ELRAE TECHNOLOGIES LTD shall ensure that consumers have access to
          itemize details of all charges either on the bill or on a separate
          statement provided to the Consumer upon request.
        </p>
        <h2>15. TIMING FOR ISSUANCE OF BILL</h2>
        <p>
          15.1 ELRAE TECHNOLOGIES LTD shall issue bills and include all charges
          incurred within the specified billing period within 10 days of the
          closure of each billing period.
        </p>
        <p>
          15.2 Exceptions may occur whereby all charges are not included on the
          invoice as a result of separate agreement between parties, or any
          other reason
        </p>
        <h2>16. RECEIPT AND CONSUMER PAYMENT ADVICE</h2>
        <p>
          16.1 ELRAE TECHNOLOGIES LTD shall make available appropriate and
          accessible methods of verification of bill payment by the Consumer.
        </p>
        <h2>17. BILLING FREQUENCY</h2>
        <p>
          17.1The billing frequency shall be a period of (60) days. Consumer
          shall be provided with sufficient and advance written notification of
          any proposed changes to the billing period. The advance written
          notification shall be deemed sufficient by parties where it provides a
          minimum notification period of twice the usual billing period(s).
        </p>
        <h2>18. NON-PAYMENT OF BILLS</h2>
        <p>
          18.1 In the event of non-payment of bills to ELRAE TECHNOLOGIES LTD,
          ELRAE TECHNOLOGIES LTD shall take necessary measures (which includes
          but not limited to referring the issue of non-payment by the consumer
          to the NCC) to effect such payment or disconnect the consumer from the
          service.
        </p>
        <p>
          18.2 Necessary measures as referred to above shall be commensurate and
          not unduly discriminatory.
        </p>
        <h2>19. BILLING OF PREPAID CUSTOMERS</h2>
        <p>
          19.1. ELRAE TECHNOLOGIES LTD shall ensure that prepaid customers are
          billed transparently and accurately. Billing for prepaid services
          shall occur in real-time, with the applicable charges deducted from
          the customer’s prepaid balance at the point of service usage.
        </p>
        <h2>20. INFORMATION TO CONSUMERS</h2>
        <p>
          20.1 ELRAE TECHNOLOGIES LTD shall ensure that its complaints procedure
          is accessible in various media and formats or as directly specified by
          the NCC from time to time. Information on the complaints procedure
          shall include:
        </p>
        <p>
          • Consumers right to lodge complaint • Mode of lodging the complaint
          to the Host • Requisite documents required to lodgE a valid complaint
          • Means to enquire on status of complaint
        </p>
        <p>
          20.2 The procedure must be expressed in clear language and the
          Consumer must be able identify how to lodge a complaint either
          physically or via dedicated online platforms
        </p>
        <p>
          20.3 Consumer complaints shall be duly recorded and processed in
          accordance with identified practices and procedures:
        </p>
        <h2>22. COMPLAINT PROCESS</h2>
        <p>
          22.1 Complaints shall be acknowledged by the ELRAE TECHNOLOGIES LTD
          verbally or in writing but preferably in the mode or manner requested
          by the Consumer complainant. Consumer shall forward complaints to the
          registered address of the Host ELRAE TECHNOLOGIES LTD as stated below:
        </p>
        <p>
          25 & 26, Victoria Ironsi Crescent, Off 41 Road, Abuja, F.C.T. Hotline:
          08090789966, 09160009848, 08090669966, 08090659966. Email:
          support@nationallotterynigeria.com
        </p>
        <p>
          22.2 Where possible, the Consumer shall be provided with an expected
          outcome or estimated timeframe within which the complaint shall be
          investigated and resolved. Notwithstanding the forgoing, complaints
          including those which require further recourse for lack of acceptable
          resolution, shall be acted upon within the set time frame as directed
          by the NCC from time to time or as provided in the annexed Quality of
          Service Regulations and this shall not exceed a period of three
          calendar months.
        </p>
        <p>
          22.3 An identified escalation process shall be accessible to the
          Consumer where Consumer is dissatisfied with the outcome of a
          complaint resolution. Such escalation process shall involve further
          complaint examination by a suitably qualified authorized
          representative of the Host.
        </p>
        <p>
          22.4 Consumers shall be duly informed where resolution via the
          escalation process has been exhausted and there are no further
          escalation processes.
        </p>
        <p>
          22.5 Oral or non-written complaints shall be deemed acknowledged by
          ELRAE TECHNOLOGIES LTD at the time such was communicated to ELRAE
          TECHNOLOGIES LTD.
        </p>
        <h2>23. PREVENTION OF UNSOLICITED SMS</h2>
        <p>
          23.1 ELRAE TECHNOLOGIES LTD shall ensure all communications comply
          with NCC’s DND requirements. Customers shall also have the option to
          opt out of receiving messages at any time by contacting the Mobile
          Network Provide
        </p>
        <h2>24. CHARGES</h2>
        <p>
          24.1 ELRAE TECHNOLOGIES LTD Complaint handling processes shall be
          provided free of charge. However, any complain that requires the
          retrieval of records more than Twelve (12) months old, shall attract
          charges which the consumers must be informed and agreed to.
        </p>
        <h2>25. FURTHER RECOURSE</h2>
        <p>
          25.1 In addition to the complaint process set up by ELRAE TECHNOLOGIES
          LTD, the consumer reserves the right to escalate unsatisfactorily
          resolved or unresolved disputes to the Nigerian Communication
          Commission (NCC).
        </p>
        <p>
          25.2 ELRAE TECHNOLOGIES LTD shall inform consumers after 60 days of
          non-resolution of the complaint to the satisfaction of the consumer to
          proceed to refer the complaint to the NCC.
        </p>

        <h2>26. ACTION ON DISPUTED CHARGES</h2>
        <p>
          26.1 Where a Consumer has initiated a complaint through the laid down
          process and investigation is ongoing, ELRAE TECHNOLOGIES LTD shall be
          estopped from taking any action with regard to credit management
          action or disconnection of installed equipment or related apparatus
          pending the resolution of the dispute.
        </p>

        <h2>27. INTERNAL DATA COLLECTION AND ANALYSIS</h2>
        <p>
          27.1 ELRAE TECHNOLOGIES LTD shall ensure availability of appropriate
          recording system for complaints and outcomes which shall comply with
          the requirements of the Commission's Quality of Service Regulations
          such that recurring issues are easily tracked for effective processing
        </p>

        <h2>28. CHANGES TO COMPLAINT HANDLING PROCESS</h2>
        <p>
          28.1 ELRAE TECHNOLOGIES LTD shall ensure that Consumer is properly
          updated with any information regarding the changes in the Company's
          complaint handling process, if any.
        </p>

        <h2>29. RETENTION OF RECORDS</h2>
        <p>
          29.1 Information collated and recorded by Host in respect of the
          complaint handling procedure initiated by the Consumer shall be
          retained for at least twenty four (24) months following resolution of
          Consumer’s complaint.
        </p>

        <h2>30. PROTECTION OF CONSUMER INFORMATION</h2>
        <p>
          30.1 Data Gathering: ELRAE TECHNOLOGIES LTD shall collect consumer
          data only as necessary for service provision, billing, and compliance
          with regulatory requirements, ensuring transparency in how data is
          obtained.
        </p>
        <p>
          30.2 Data Storage: All consumer data shall be securely stored in
          compliance with NCC regulations and applicable data protection laws,
          employing measures to prevent unauthorized access or breaches
        </p>
        <p>
          30.3 Data Sharing: Consumer data shall not be shared with third
          parties without explicit consent, except as required by law or
          regulatory obligations. Any data sharing shall align with NCC’s
          guidelines and applicable data protection policies.
        </p>

        <h2>31. CONSUMER OBLIGATIONS</h2>
        <p>
          31.1 Consumers are obligated to provide accurate information, comply
          with service terms and laws, use services responsibly, and make timely
          payments. Consumers must also engage constructively in resolving
          disputes through the outlined complaint procedures.
        </p>

        <h2>32. SERVICE SUBSCRIPTION</h2>
        <p>
          ELRAE TECHNOLOGIES LTD does not render a subscription-based service
          with regularly timed deductions. To utilize the service customers dial
          *9966# or any other short codes which may be associated with the Value
          Added Services from time to time. Customers may then purchase 1 or
          more tickets priced at NGN100 using airtime.
        </p>

        <h2>33. OPT-IN AND OPT-OUT</h2>
        <p>
          Since ELRAE TECHNOLOGIES LTD’s VAS services are accessed on a per-use
          basis, users opt-in by engaging with the service (dialing the USSD
          code and selecting an option). To opt-out and avoid charges for unused
          services, users can simply refrain from interacting with the USSD menu
          or shortcodes associated with the service.
        </p>
      </div>
    </div>
  );
};

export default Ccp;
